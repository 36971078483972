// Client Data Platform parameters
//export const DS_BASE_URL = 'https://api.digmstools.com'; // PROD
export const DS_BASE_URL = 'https://stage-api.digmstools.com'; // STAGE
export const CLIENTS_ENDPOINT = '/clients'; // PROD
export const UPDATE_A_CLIENT_ENDPOINT = "/clients"; // PROD
export const GET_ALL_PACKAGES_ENDPOINT = "/clients/packages"; // PROD
export const GET_ALL_VERTICALS_ENDPOINT = "/clients/verticals"; // PROD
export const GET_ALL_PLATFORMS_ENDPOINT = "/clients/platforms"

// Authentication
//export const APP_BASE_URL = "https://cdp.digmstools.com" // PROD
export const APP_BASE_URL = 'https://stage.cdp.digmstools.com'; // STAGE
// export const APP_BASE_URL_DEV = "http://localhost:3000"; // DEV
